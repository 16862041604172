import { Action } from '@ngrx/store';

export enum ComponentStateActionEnum {
  SAVE_MAIN_STATE = '[Main] Save state',
  SAVE_SORT_FILTER_STATE = '[SortFilter] Save state',
  SAVE_USER_MANAGER_STATE = '[UserManager] Save state',
  SAVE_PROJECT_MANAGER_STATE = '[ProjectManager] Save state',
  SAVE_DEVICE_MANAGER_STATE = '[DeviceManager] Save state',
  SAVE_MEDIA_MANAGER_STATE = '[MediaManager] Save state',
  SAVE_INDEX_WORD_STATE = '[IndexWord] Save state',
  SAVE_MASTER_LIST_STATE = '[MasterList] Save state',
  SAVE_ROUTE_LIST_STATE = '[RouteList] Save state',
  SAVE_LCD_EDITOR_STATE = '[LCDEditor] Save state',
  SAVE_LED_EDITOR_STATE = '[LEDEditor] Save state',
  SAVE_DESTINATION_SIGN_EDITOR_STATE = '[DestinationSignEditor] Save state',
  SAVE_BUS_INFO_DISPLAY_EDITOR_STATE = '[BusInfoDisplayEditor] Save state',
  SAVE_DIGITAL_SIGNAGE_EDITOR_STATE = '[DigitalSignageEditor] Save state',
  SAVE_DELIVERY_MANAGER_STATE = '[DeliveryManager] Save state',
  SAVE_DASHBOARD_STATE = '[Dashboard] Save state',
  SAVE_STATION_DISPLAY_EDITOR_STATE = '[StationDisplayEditor] Save state',
  SAVE_SIGNAGE_DISPLAY_EDITOR_STATE = '[SignageDisplayEditor] Save state',
  SAVE_EXTERNAL_CONTENT_MANAGER_STATE = '[ExternalContentManager] Save state',
  SAVE_USER_INFO_STATE = '[UserInfo] Save state',
  SAVE_TIMETABLE_EDITOR_STATE = '[TimetableEditor] Save state',
  SAVE_TIMETABLE_OPERATION_MANAGER_STATE = '[TimetableOperationManager] Save state',
  SAVE_SIMPLE_SIGNAGE_EDITOR_STATE = '[SimpleSignageEditor] Save state',
  SAVE_SORT_FILTER_USER_MANAGER_STATE = '[SortFilterUserManager] Save state',
  SAVE_SORT_FILTER_LCD_LAYOUT_EDITOR_STATE = '[SortFilterLCDLayoutEditor] Save state',

  RESET_MAIN_STATE = '[Main] Reset state',
  RESET_SORT_FILTER_STATE = '[SortFilter] Reset state',
  RESET_USER_MANAGER_STATE = '[UserManager] Reset state',
  RESET_PROJECT_MANAGER_STATE = '[ProjectManager] Reset state',
  RESET_DEVICE_MANAGER_STATE = '[DeviceManager] Reset state',
  RESET_MEDIA_MANAGER_STATE = '[MediaManager] Reset state',
  RESET_INDEX_WORD_STATE = '[IndexWord] Reset state',
  RESET_MASTER_LIST_STATE = '[MasterList] Reset state',
  RESET_ROUTE_LIST_STATE = '[RouteList] Reset state',
  RESET_LCD_EDITOR_STATE = '[LCDEditor] Reset state',
  RESET_LED_EDITOR_STATE = '[LEDEditor] Reset state',
  RESET_DESTINATION_SIGN_EDITOR_STATE = '[DestinationSignEditor] Reset state',
  RESET_BUS_INFO_DISPLAY_EDITOR_STATE = '[BusInfoDisplayEditor] Reset state',
  RESET_DIGITAL_SIGNAGE_EDITOR_STATE = '[DigitalSignageEditor] Reset state',
  RESET_DELIVERY_MANAGER_STATE = '[DeliveryManager] Reset state',
  RESET_DASHBOARD_STATE = '[Dashboard] Reset state',
  RESET_STATION_DISPLAY_EDITOR_STATE = '[StationDisplayEditor] Reset state',
  RESET_SIGNAGE_DISPLAY_EDITOR_STATE = '[SignageDisplayEditor] Reset state',
  RESET_EXTERNAL_CONTENT_MANAGER_STATE = '[ExternalContentManager] Reset state',
  RESET_USER_INFO_STATE = '[UserInfo] Reset state',
  RESET_TIMETABLE_EDITOR_STATE = '[TimetableEditor] Reset state',
  RESET_TIMETABLE_OPERATION_MANAGER_STATE = '[TimetableOperationManager] Reset state',
  RESET_SIMPLE_SIGNAGE_EDITOR_STATE = '[SimpleSignageEditor] Reset state',
  RESET_SORT_FILTER_USER_MANAGER_STATE = '[SortFilterUserManager] Reset state',
  RESET_SORT_FILTER_LCD_LAYOUT_EDITOR_STATE = '[SortFilterLCDLayoutEditor] Reset state'
}

export class SaveMainStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_MAIN_STATE;
  constructor(public payload: any) {}
}

export class ResetMainStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_MAIN_STATE;
  constructor() {}
}

export class SaveSortFilterStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_SORT_FILTER_STATE;
  constructor(public payload: any) {}
}

export class ResetSortFilterStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_SORT_FILTER_STATE;
  constructor() {}
}
export class SaveSortFilterUserManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_SORT_FILTER_USER_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetSortFilterUserManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_SORT_FILTER_USER_MANAGER_STATE;
  constructor() {}
}

export class SaveSortFilterLCDLayoutEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_SORT_FILTER_LCD_LAYOUT_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetSortFilterLCDLayoutEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_SORT_FILTER_LCD_LAYOUT_EDITOR_STATE;
  constructor() {}
}

// User Manager action
export class SaveUserManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_USER_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetUserManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_USER_MANAGER_STATE;
  constructor() {}
}

// Project Manager action
export class SaveProjectManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_PROJECT_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetProjectManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_PROJECT_MANAGER_STATE;
  constructor() {}
}

// Device Manager action
export class SaveDeviceManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_DEVICE_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetDeviceManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_DEVICE_MANAGER_STATE;
  constructor() {}
}

// Media Manager action
export class SaveMediaManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_MEDIA_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetMediaManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_MEDIA_MANAGER_STATE;
  constructor() {}
}

// Index Word action
export class SaveIndexWordStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_INDEX_WORD_STATE;
  constructor(public payload: any) {}
}

export class ResetIndexWordStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_INDEX_WORD_STATE;
  constructor() {}
}

// Master List action
export class SaveMasterListStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_MASTER_LIST_STATE;
  constructor(public payload: any) {}
}

export class ResetMasterListStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_MASTER_LIST_STATE;
  constructor() {}
}

// Route List action
export class SaveRouteListStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_ROUTE_LIST_STATE;
  constructor(public payload: any) {}
}

export class ResetRouteListStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_ROUTE_LIST_STATE;
  constructor() {}
}

// LCD Editor action
export class SaveLCDEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_LCD_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetLCDEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_LCD_EDITOR_STATE;
  constructor() {}
}

// LED Editor action
export class SaveLEDEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_LED_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetLEDEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_LED_EDITOR_STATE;
  constructor() {}
}

// Destination Sign Editor action
export class SaveDestinationSignEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_DESTINATION_SIGN_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetDestinationSignEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_DESTINATION_SIGN_EDITOR_STATE;
  constructor() {}
}

// Bus Info Display Editor action
export class SaveBusInfoDisplayEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_BUS_INFO_DISPLAY_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetBusInfoDisplayEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_BUS_INFO_DISPLAY_EDITOR_STATE;
  constructor() {}
}

// Digital Signage Editor action
export class SaveDigitalSignageEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_DIGITAL_SIGNAGE_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetDigitalSignageEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_DIGITAL_SIGNAGE_EDITOR_STATE;
  constructor() {}
}

// Delivery Manager action
export class SaveDeliveryManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_DELIVERY_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetDeliveryManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_DELIVERY_MANAGER_STATE;
  constructor() {}
}

// Dashboard action
export class SaveDashboardStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_DASHBOARD_STATE;
  constructor(public payload: any) {}
}

export class ResetDashboardStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_DASHBOARD_STATE;
  constructor() {}
}

// Station action
export class SaveStationDisplayEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_STATION_DISPLAY_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetStationDisplayEditorAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_STATION_DISPLAY_EDITOR_STATE;
  constructor() {}
}

// Signage action
export class SaveSignageDisplayEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_SIGNAGE_DISPLAY_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetSignageDisplayEditorAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_SIGNAGE_DISPLAY_EDITOR_STATE;
  constructor() {}
}

export class SaveExternalContentManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_EXTERNAL_CONTENT_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetExternalContentManagerAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_EXTERNAL_CONTENT_MANAGER_STATE;
  constructor() {}
}

export class SaveUserInfoAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_USER_INFO_STATE;
  constructor(public payload: any) {}
}

export class ResetUserInfoAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_USER_INFO_STATE;
  constructor(public payload: any) {}
}

// Timetable action
export class SaveTimetableEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_TIMETABLE_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetTimetableEditorAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_TIMETABLE_EDITOR_STATE;
  constructor() {}
}

export class SaveTimetableOperationManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_TIMETABLE_OPERATION_MANAGER_STATE;
  constructor(public payload: any) {}
}

export class ResetTimetableOperationManagerStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_TIMETABLE_OPERATION_MANAGER_STATE;
  constructor() {}
}

export class SaveSimpleSignageEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.SAVE_SIMPLE_SIGNAGE_EDITOR_STATE;
  constructor(public payload: any) {}
}

export class ResetSimpleSignageEditorStateAction implements Action {
  readonly type = ComponentStateActionEnum.RESET_SIMPLE_SIGNAGE_EDITOR_STATE;
  constructor() {}
}

export type AppActions =
  | SaveMainStateAction
  | SaveSortFilterStateAction
  | SaveUserManagerStateAction
  | SaveProjectManagerStateAction
  | SaveDeviceManagerStateAction
  | SaveMediaManagerStateAction
  | SaveIndexWordStateAction
  | SaveMasterListStateAction
  | SaveRouteListStateAction
  | SaveLCDEditorStateAction
  | SaveLEDEditorStateAction
  | SaveDestinationSignEditorStateAction
  | SaveBusInfoDisplayEditorStateAction
  | SaveDigitalSignageEditorStateAction
  | SaveDeliveryManagerStateAction
  | SaveDashboardStateAction
  | SaveStationDisplayEditorStateAction
  | SaveSignageDisplayEditorStateAction
  | SaveExternalContentManagerStateAction
  | SaveUserInfoAction
  | SaveTimetableEditorStateAction
  | SaveTimetableOperationManagerStateAction
  | SaveSimpleSignageEditorStateAction
  | SaveSortFilterUserManagerStateAction
  | SaveSortFilterLCDLayoutEditorStateAction
  | ResetMainStateAction
  | ResetSortFilterStateAction
  | ResetUserManagerStateAction
  | ResetProjectManagerStateAction
  | ResetDeviceManagerStateAction
  | ResetMediaManagerStateAction
  | ResetIndexWordStateAction
  | ResetMasterListStateAction
  | ResetRouteListStateAction
  | ResetLCDEditorStateAction
  | ResetLEDEditorStateAction
  | ResetDestinationSignEditorStateAction
  | ResetBusInfoDisplayEditorStateAction
  | ResetDigitalSignageEditorStateAction
  | ResetDeliveryManagerStateAction
  | ResetDashboardStateAction
  | ResetStationDisplayEditorAction
  | ResetSignageDisplayEditorAction
  | ResetExternalContentManagerAction
  | ResetUserInfoAction
  | ResetTimetableEditorAction
  | ResetTimetableOperationManagerStateAction
  | ResetSimpleSignageEditorStateAction
  | ResetSortFilterLCDLayoutEditorStateAction
  | ResetSortFilterUserManagerStateAction;
