import { Constant, DestinationEnum, EditTemplateToolsEnum, LEDTemplateModeEnum, TemplateModeEnum } from 'app/config/constants';
import { EmergencyData } from 'app/model/entity/emergency-data';
import { ScaleEntity } from 'app/model/entity/scale';
import { AppActions, ComponentStateActionEnum } from './component-state.action';
import {
  IBusInfoDisplayEditorComponentState,
  IDashboardComponentState,
  IDeliveryManagerComponentState,
  IDestinationSignEditorComponentState,
  IDeviceManagerComponentState,
  IDigitalSignageEditorComponentState,
  IExternalContentManagerComponentState,
  IIndexWordComponentState,
  ILCDEditorComponentState,
  ILEDEditorComponentState,
  IMainState,
  IMasterListComponentState,
  IMediaManagerComponentState,
  IProjectManagerComponentState,
  IRouteListComponentState,
  ISignageDisplayEditorComponentState,
  ISimpleSignageEditorComponentState,
  ISortFilterLCDLayoutEditorState,
  ISortFilterState,
  ISortFilterUserManagerState,
  IStationDisplayEditorComponentState,
  ITimetableEditorComponentState,
  ITimetableOperationManagerComponentState,
  IUserInfoState,
  IUserManagerComponentState
} from './component-state.state';

// Initial Main State
const initialMainState: IMainState = {
  common: undefined
};
const mainStartupState: any = {
  stateOfComponent: initialMainState
};

export function handleMainReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_MAIN_STATE:
      return {
        ...state,
        stateOfComponent: {
          common: action.payload.common
        }
      };
    case ComponentStateActionEnum.RESET_MAIN_STATE:
      return {
        ...state,
        stateOfComponent: initialMainState
      };
    default:
      return state;
  }
}

export function mainReducer(state = mainStartupState, action: AppActions) {
  return handleMainReducer(state, action);
}

// Initial Sort Filter State
const initialSortFilter: ISortFilterState = {
  sortFilterTimetable: undefined
};
const sortFilterStartupState: any = {
  stateOfComponent: initialSortFilter
};

export function handleSortFilterReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_SORT_FILTER_STATE:
      return {
        ...state,
        stateOfComponent: {
          sortFilterTimetable: action.payload.sortFilterTimetable
        }
      };
    case ComponentStateActionEnum.RESET_SORT_FILTER_STATE:
      return {
        ...state,
        stateOfComponent: initialSortFilter
      };
    default:
      return state;
  }
}

export function sortFilterReducer(state = sortFilterStartupState, action: AppActions) {
  return handleSortFilterReducer(state, action);
}

// Initial Sort Filter User Manager State
const initialSortFilterUserManager: ISortFilterUserManagerState = {
  sortFilterUser: undefined
};
const sortFilterUserManagerStartupState: any = {
  stateOfComponent: initialSortFilterUserManager
};

export function sortFilterUserManagerReducer(state = sortFilterUserManagerStartupState, action: AppActions) {
  return handleSortFilterUserManagerReducer(state, action);
}

export function handleSortFilterUserManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_SORT_FILTER_USER_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          sortFilterUser: action.payload.sortFilterUser
        }
      };
    case ComponentStateActionEnum.RESET_SORT_FILTER_USER_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialSortFilterUserManager
      };
    default:
      return state;
  }
}

// Initial Sort Filter LCD Layout Editor State
const initialSortFilterLCDLayoutEditor: ISortFilterLCDLayoutEditorState = {
  sortFilterLCDLayoutEditor: undefined
};
const sortFilterLCDLayoutEditorStartupState: any = {
  stateOfComponent: initialSortFilterLCDLayoutEditor
};

export function sortFilterLCDLayoutEditorReducer(state = sortFilterLCDLayoutEditorStartupState, action: AppActions) {
  return handleSortFilterLCDLayoutEditorReducer(state, action);
}

export function handleSortFilterLCDLayoutEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_SORT_FILTER_LCD_LAYOUT_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          sortFilterLCDLayoutEditor: action.payload.sortFilterLCDLayoutEditor
        }
      };
    case ComponentStateActionEnum.RESET_SORT_FILTER_LCD_LAYOUT_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialSortFilterLCDLayoutEditor
      };
    default:
      return state;
  }
}

// Initial Project Manager State
const initialProjectManagerState: IProjectManagerComponentState = {
  isChangeLayout: false,
  projects: undefined,
  routes: undefined,
  busStops: undefined,
  channels: undefined,
  styles: undefined,
  publishDestinations: undefined,
  publishInfoBIDs: undefined,
  publishInfoDSCs: undefined,
  publishInfoBSDs: undefined,
  publishInfoGIDs: undefined,
  projectSelected: undefined,
  projectOpened: undefined,
  isActiveTabDestination: false,
  isActiveTabBusInform: false,
  isActiveTabSignage: false,
  isActiveTabStation: false,
  isActiveTabSignageDisplay: false,
  isShowDetail: false,
  isChangedData: false,
  channelSelected: undefined,
  routeSelected: undefined,
  busStopSelected: undefined,
  styleSelected: undefined
};
const projectManagerStartupState: any = {
  stateOfComponent: initialProjectManagerState
};

export function handleProjectManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_PROJECT_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          projects: action.payload.projects,
          routes: action.payload.routes,
          busStops: action.payload.busStops,
          channels: action.payload.channels,
          styles: action.payload.styles,
          publishDestinations: action.payload.publishDestinations,
          publishInfoBIDs: action.payload.publishInfoBIDs,
          publishInfoDSCs: action.payload.publishInfoDSCs,
          publishInfoBSDs: action.payload.publishInfoBSDs,
          publishInfoGIDs: action.payload.publishInfoGIDs,
          projectSelected: action.payload.projectSelected,
          projectOpened: action.payload.projectOpened,
          isActiveTabDestination: action.payload.isActiveTabDestination,
          isActiveTabBusInform: action.payload.isActiveTabBusInform,
          isActiveTabSignage: action.payload.isActiveTabSignage,
          isActiveTabStation: action.payload.isActiveTabStation,
          isActiveTabSignageDisplay: action.payload.isActiveTabSignageDisplay,
          isShowDetail: action.payload.isShowDetail,
          isChangedData: action.payload.isChangedData,
          channelSelected: action.payload.channelSelected,
          routeSelected: action.payload.routeSelected,
          busStopSelected: action.payload.busStopSelected,
          styleSelected: action.payload.styleSelected
        }
      };
    case ComponentStateActionEnum.RESET_PROJECT_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialProjectManagerState
      };
    default:
      return state;
  }
}

export function projectManagerReducer(state = projectManagerStartupState, action: AppActions) {
  return handleProjectManagerReducer(state, action);
}

// Initial User Manager State
const initialUserManagerState: IUserManagerComponentState = {
  isChangeLayout: false,
  listUser: undefined,
  privileges: undefined,
  privilegesOld: undefined,
  selectedUser: undefined,
  isShowUserList: true,
  isChangedData: false,
  listUserDisplay: [],
  isSortFilter: false,
  isShowPopUpSortFilter: false,
  isCheckAllOptionFilter: false,
  columnSortFiltering: undefined,
  lastColumnFilter: undefined,
  listFilterDisplay: [],
  listFilterDisplayOrigin: [],
  isFilter: false,
  isClear: false,
  listCurrentFilter: {},
  listSorted: [],
  headerColumns: [],
  headerColumnsOriginal: []
};
const userManagerStartupState: any = {
  stateOfComponent: initialUserManagerState
};

export function handleUserManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_USER_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          listUser: action.payload.listUser,
          privileges: action.payload.privileges,
          privilegesOld: action.payload.privilegesOld,
          selectedUser: action.payload.selectedUser,
          isShowUserList: action.payload.isShowUserList,
          isChangedData: action.payload.isChangedData,
          listUserDisplay: action.payload.listUserDisplay,
          isSortFilter: action.payload.isSortFilter,
          isShowPopUpSortFilter: action.payload.isShowPopUpSortFilter,
          isCheckAllOptionFilter: action.payload.isCheckAllOptionFilter,
          columnSortFiltering: action.payload.columnSortFiltering,
          lastColumnFilter: action.payload.lastColumnFilter,
          listFilterDisplay: action.payload.listFilterDisplay,
          listFilterDisplayOrigin: action.payload.listFilterDisplayOrigin,
          isFilter: action.payload.isFilter,
          isClear: action.payload.isClear,
          listCurrentFilter: action.payload.listCurrentFilter,
          listSorted: action.payload.listSorted,
          headerColumns: action.payload.headerColumns,
          headerColumnsOriginal: action.payload.headerColumnsOriginal
        }
      };
    case ComponentStateActionEnum.RESET_USER_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialUserManagerState
      };
    default:
      return state;
  }
}

export function userManagerReducer(state = userManagerStartupState, action: AppActions) {
  return handleUserManagerReducer(state, action);
}

// Initial Device Manager State
const initialDeviceManagerState: IDeviceManagerComponentState = {
  isChangeLayout: false,
  devices: undefined,
  devicesDisplay: undefined,
  devicesFilterDisplay: undefined,
  listCustomTag: [],
  listFilterDisplayOrigin: undefined,
  listFilterDisplay: undefined,
  columns: [],
  listSorted: [],
  deviceSelected: undefined,
  lastFiltered: Constant.EMPTY,
  listCurrentFilter: {},
  lastColumn: Constant.EMPTY,
  hasComment: true,
  isClear: false,
  isSortAndFilter: false,
  isCheckAll: false,
  isSort: '',
  isFilter: false,
  isChangedData: false
};
const deviceManagerStartupState: any = {
  stateOfComponent: initialDeviceManagerState
};

export function handleDeviceManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_DEVICE_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          devices: action.payload.devices,
          devicesDisplay: action.payload.devicesDisplay,
          devicesFilterDisplay: action.payload.devicesFilterDisplay,
          listCustomTag: action.payload.listCustomTag,
          listFilterDisplayOrigin: action.payload.listFilterDisplayOrigin,
          listFilterDisplay: action.payload.listFilterDisplay,
          columns: action.payload.columns,
          listSorted: action.payload.listSorted,
          deviceSelected: action.payload.deviceSelected,
          lastFiltered: action.payload.lastFiltered,
          listCurrentFilter: action.payload.listCurrentFilter,
          lastColumn: action.payload.lastColumn,
          hasComment: action.payload.hasComment,
          isClear: action.payload.isClear,
          isSortAndFilter: action.payload.isSortAndFilter,
          isCheckAll: action.payload.isCheckAll,
          isSort: action.payload.isSort,
          isFilter: action.payload.isFilter,
          isChangedData: action.payload.isChangedData
        }
      };
    case ComponentStateActionEnum.RESET_DEVICE_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialDeviceManagerState
      };
    default:
      return state;
  }
}

export function deviceManagerReducer(state = deviceManagerStartupState, action: AppActions) {
  return handleDeviceManagerReducer(state, action);
}

// Initial Media Manager State
const initialMediaManagerState: IMediaManagerComponentState = {
  isChangeLayout: false,
  folders: undefined,
  folderSelected: undefined,
  mediaSelected: undefined,
  isCreateSequence: false,
  isActiveNextButton: false,
  isActivePreButton: false,
  mediaOfSequences: undefined,
  mediaTypeFilter: undefined,
  isEditMedia: false,
  mediaNameEdit: '',
  mediaOwnerEdit: '',
  mediaTextEdit: '',
  mediaDescriptionEdit: '',
  isMediaOfSequenceSelected: false,
  totalDurationOfSequence: '',
  mediaOfSequenceSelected: undefined,
  medias: undefined,
  mediasOrigin: undefined,
  isShowPlaceholder: false,
  isActiveTabPreview: false,
  isActiveTabProperties: false,
  searchInputValue: '',
  isOpenStationContentFolder: false,
  mediaTypesCurrent: undefined,
  isChangedData: false,
  lastSelectedSequence: undefined,
  mediaTypeFilterOld: undefined,
  speedMedia: 1,
  userId: undefined,
  sequenceSave: undefined,
  idMediaList: undefined,
  isEditSequence: false,
  mediasSelected: undefined,
  mediasRead: undefined,
  folderContainSequence: undefined,
  mediaListOfSequence: undefined,
  isShowClipMode: false,
  timeCursorPointSecond: 0,
  valueTotalTime: '00:00:00',
  timeInSecond: undefined,
  timeOutSecond: undefined,
  timeDurationSecond: undefined,
  timeIn: undefined,
  timeOut: undefined,
  maxTime: undefined,
  pointListNumber: undefined,
  pointListPercent: undefined,
  widthCanvasPreviewSequence: undefined,
  heightCanvasPreviewSequence: undefined,
  canvasPreviewSequence: undefined
};
const mediaManagerStartupState: any = {
  stateOfComponent: initialMediaManagerState
};

export function handleMediaManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_MEDIA_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          folders: action.payload.folders,
          folderSelected: action.payload.folderSelected,
          mediaSelected: action.payload.mediaSelected,
          isCreateSequence: action.payload.isCreateSequence,
          isActiveNextButton: action.payload.isActiveNextButton,
          isActivePreButton: action.payload.isActivePreButton,
          mediaOfSequences: action.payload.mediaOfSequences,
          mediaTypeFilter: action.payload.mediaTypeFilter,
          isEditMedia: action.payload.isEditMedia,
          mediaNameEdit: action.payload.mediaNameEdit,
          mediaOwnerEdit: action.payload.mediaOwnerEdit,
          mediaTextEdit: action.payload.mediaTextEdit,
          mediaDescriptionEdit: action.payload.mediaDescriptionEdit,
          isMediaOfSequenceSelected: action.payload.isMediaOfSequenceSelected,
          totalDurationOfSequence: action.payload.totalDurationOfSequence,
          mediaOfSequenceSelected: action.payload.mediaOfSequenceSelected,
          medias: action.payload.medias,
          mediasOrigin: action.payload.mediasOrigin,
          isShowPlaceholder: action.payload.isShowPlaceholder,
          isActiveTabPreview: action.payload.isActiveTabPreview,
          isActiveTabProperties: action.payload.isActiveTabProperties,
          searchInputValue: action.payload.searchInputValue,
          isOpenStationContentFolder: action.payload.isOpenStationContentFolder,
          mediaTypesCurrent: action.payload.mediaTypesCurrent,
          isChangedData: action.payload.isChangedData,
          lastSelectedSequence: action.payload.lastSelectedSequence,
          mediaTypeFilterOld: action.payload.mediaTypeFilterOld,
          speedMedia: action.payload.speedMedia,
          userId: action.payload.userId,
          sequenceSave: action.payload.sequenceSave,
          idMediaList: action.payload.idMediaList,
          isEditSequence: action.payload.isEditSequence,
          mediasSelected: action.payload.mediasSelected,
          mediasRead: action.payload.mediasRead,
          folderContainSequence: action.payload.folderContainSequence,
          mediaListOfSequence: action.payload.mediaListOfSequence,
          isShowClipMode: action.payload.isShowClipMode,
          timeCursorPointSecond: action.payload.timeCursorPointSecond,
          valueTotalTime: action.payload.valueTotalTime,
          timeInSecond: action.payload.timeInSecond,
          timeOutSecond: action.payload.timeOutSecond,
          timeDurationSecond: action.payload.timeDurationSecond,
          timeIn: action.payload.timeIn,
          timeOut: action.payload.timeOut,
          maxTime: action.payload.maxTime,
          pointListNumber: action.payload.pointListNumber,
          pointListPercent: action.payload.pointListPercent,
          widthCanvasPreviewSequence: action.payload.widthCanvasPreviewSequence,
          heightCanvasPreviewSequence: action.payload.heightCanvasPreviewSequence,
          canvasPreviewSequence: action.payload.canvasPreviewSequence
        }
      };
    case ComponentStateActionEnum.RESET_MEDIA_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialMediaManagerState
      };
    default:
      return state;
  }
}

export function mediaManagerReducer(state = mediaManagerStartupState, action: AppActions) {
  return handleMediaManagerReducer(state, action);
}

// Initial Index Word Editor State
const initialIndexWordEditorState: IIndexWordComponentState = {
  isChangeLayout: false,
  indexWordGroups: undefined,
  indexWords: undefined,
  indexWordGroupSelected: undefined,
  indexWordSelected: undefined,
  isActiveDeleteGroup: false,
  isEditIndexWordGroup: false,
  isEditIndexWord: false,
  isChangedData: false,
  fileData: undefined,
  mediaValidator: undefined
};

const indexWordEditorStartupState: any = {
  stateOfComponent: initialIndexWordEditorState
};

export function handleIndexWordEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_INDEX_WORD_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          indexWordGroups: action.payload.indexWordGroups,
          indexWords: action.payload.indexWords,
          indexWordGroupSelected: action.payload.indexWordGroupSelected,
          indexWordSelected: action.payload.indexWordSelected,
          isActiveDeleteGroup: action.payload.isActiveDeleteGroup,
          isEditIndexWordGroup: action.payload.isEditIndexWordGroup,
          isEditIndexWord: action.payload.isEditIndexWord,
          isChangedData: action.payload.isChangedData,
          fileData: action.payload.fileData,
          mediaValidator: action.payload.mediaValidator
        }
      };
    case ComponentStateActionEnum.RESET_INDEX_WORD_STATE:
      return {
        ...state,
        stateOfComponent: initialIndexWordEditorState
      };
    default:
      return state;
  }
}

export function indexWordEditorReducer(state = indexWordEditorStartupState, action: AppActions) {
  return handleIndexWordEditorReducer(state, action);
}

// Initial Master List State
const initialMasterListState: IMasterListComponentState = {
  isChangeLayout: false,
  masterBusStopList: undefined,
  indexWordHeaders: undefined,
  indexWordGroups: undefined,
  indexWordOfBusStop: [],
  selectedBusStop: undefined,
  selectedIndexWordGroup: undefined,
  pagingConfig: {
    id: 'advanced',
    itemsPerPage: Constant.RECORDS_PER_PAGE,
    currentPage: 1,
    totalItems: 0
  },
  loading: false,
  isCheckedAll: false,
  isChangedData: false
};
const masterListStartupState: any = {
  stateOfComponent: initialMasterListState
};

export function handleMasterListEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_MASTER_LIST_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          masterBusStopList: action.payload.masterBusStopList,
          indexWordHeaders: action.payload.indexWordHeaders,
          indexWordGroups: action.payload.indexWordGroups,
          indexWordOfBusStop: action.payload.indexWordOfBusStop,
          selectedBusStop: action.payload.selectedBusStop,
          selectedIndexWordGroup: action.payload.selectedIndexWordGroup,
          pagingConfig: action.payload.pagingConfig,
          loading: action.payload.loading,
          isCheckedAll: action.payload.isCheckedAll,
          isChangedData: action.payload.isChangedData
        }
      };
    case ComponentStateActionEnum.RESET_MASTER_LIST_STATE:
      return {
        ...state,
        stateOfComponent: initialMasterListState
      };
    default:
      return state;
  }
}

export function masterListEditorReducer(state = masterListStartupState, action: AppActions) {
  return handleMasterListEditorReducer(state, action);
}

// Initial Route List Editor State
const initialRouteListState: IRouteListComponentState = {
  isChangeLayout: false,
  routes: [],
  routeSelected: undefined,
  busStopSelected: undefined,
  routeNoEdit: undefined,
  routeSuffixEdit: undefined,
  routeNameEdit: undefined,
  isAbleDrop: true,
  isShowDetail: false,
  isCheckedAll: false,
  isAddRoute: false,
  isChangedData: false,
  busStopsOfRoute: []
};
const routeListStartupState: any = {
  stateOfComponent: initialRouteListState
};

export function handleRouteListEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_ROUTE_LIST_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          routes: action.payload.routes,
          routeSelected: action.payload.routeSelected,
          busStopSelected: action.payload.busStopSelected,
          routeNoEdit: action.payload.routeNoEdit,
          routeSuffixEdit: action.payload.routeSuffixEdit,
          routeNameEdit: action.payload.routeNameEdit,
          isAbleDrop: action.payload.isAbleDrop,
          isShowDetail: action.payload.isShowDetail,
          isCheckedAll: action.payload.isCheckedAll,
          isAddRoute: action.payload.isAddRoute,
          isChangedData: action.payload.isChangedData,
          busStopsOfRoute: action.payload.busStopsOfRoute
        }
      };
    case ComponentStateActionEnum.RESET_ROUTE_LIST_STATE:
      return {
        ...state,
        stateOfComponent: initialRouteListState
      };
    default:
      return state;
  }
}

export function routeListEditorReducer(state = routeListStartupState, action: AppActions) {
  return handleRouteListEditorReducer(state, action);
}

// Initial LCD Editor Editor State
const initialLCDEditorState: ILCDEditorComponentState = {
  isChangeLayout: false,
  fonts: [],
  isShowedTemplateEditor: false,
  isHiddenTemplateListArea: false,
  isHiddenSettingArea: false,
  isHiddenLayerListArea: false,
  templateGroupsOrigin: undefined,
  templateGroups: undefined,
  templateGroupSelected: undefined,
  templatesOfGroup: undefined,
  templateSelected: undefined,
  templateSelectedOld: undefined,
  layerSelected: undefined,
  areaSelected: undefined,
  toolEditTemplateSelected: EditTemplateToolsEnum.SELECT_AREA,
  scalePreview: new ScaleEntity('100', 1),
  indexWordGroups: undefined,
  soundFiles: [],
  isClearAllBorderCanvas: false,
  templateEditorScreenState: {
    template: undefined,
    canUndo: false,
    canRedo: false,
    areaSelectedSymbol: undefined
  },
  isNotTemplate: false,
  areasDefault: [],
  templateGroupModeFilter: TemplateModeEnum.ALL,
  fontColor: undefined,
  backgroundColor: undefined,
  areaSelectedArray: [],
  isSelectedFolder: false,
  isChangedData: false,
  idAreaLinkTextEmergencyOption: undefined,
  idAreaLinkPictureEmergencyOption: undefined,
  isTypeList: false,
  searchInputValue: undefined,
  filesData: [],
  mediaFilesDropped: new Array(),
  isSortFilter: false,
  isShowPopUpSortFilter: false,
  isCheckAllOptionFilter: true,
  isFilter: false,
  isClear: false,
  columnSortFiltering: undefined,
  lastColumnFilter: undefined,
  listFilterDisplay: [],
  listFilterDisplayOrigin: [],
  listCurrentFilter: {},
  listSorted: [],
  templateGroupsDisplay: [],
  headerColumns: [],
  headerColumnsOriginal: [],
  mediaValidator: undefined,
  areaGroups: [],
  isGroup: false,
  groupSelected: undefined,
  isMultipleTimetables: false
};
const lcdEditorStartupState: any = {
  stateOfComponent: initialLCDEditorState
};

export function handleLcdLayoutEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_LCD_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          fonts: action.payload.fonts,
          isShowedTemplateEditor: action.payload.isShowedTemplateEditor,
          isHiddenTemplateListArea: action.payload.isHiddenTemplateListArea,
          isHiddenSettingArea: action.payload.isHiddenSettingArea,
          isHiddenLayerListArea: action.payload.isHiddenLayerListArea,
          templateGroupsOrigin: action.payload.templateGroupsOrigin,
          templateGroups: action.payload.templateGroups,
          templateGroupSelected: action.payload.templateGroupSelected,
          templatesOfGroup: action.payload.templatesOfGroup,
          templateSelected: action.payload.templateSelected,
          templateSelectedOld: action.payload.templateSelectedOld,
          layerSelected: action.payload.layerSelected,
          areaSelected: action.payload.areaSelected,
          toolEditTemplateSelected: action.payload.toolEditTemplateSelected,
          scalePreview: action.payload.scalePreview,
          indexWordGroups: action.payload.indexWordGroups,
          soundFiles: action.payload.soundFiles,
          isClearAllBorderCanvas: action.payload.isClearAllBorderCanvas,
          templateEditorScreenState: action.payload.templateEditorScreenState,
          isNotTemplate: action.payload.isNotTemplate,
          areasDefault: action.payload.areasDefault,
          templateGroupModeFilter: action.payload.templateGroupModeFilter,
          fontColor: action.payload.fontColor,
          backgroundColor: action.payload.backgroundColor,
          areaSelectedArray: action.payload.areaSelectedArray,
          isSelectedFolder: action.payload.isSelectedFolder,
          isChangedData: action.payload.isChangedData,
          idAreaLinkTextEmergencyOption: action.payload.idAreaLinkTextEmergencyOption,
          idAreaLinkPictureEmergencyOption: action.payload.idAreaLinkPictureEmergencyOption,
          isTypeList: action.payload.isTypeList,
          searchInputValue: action.payload.searchInputValue,
          filesData: action.payload.filesData,
          mediaFilesDropped: action.payload.mediaFilesDropped,
          isSortFilter: action.payload.isSortFilter,
          isShowPopUpSortFilter: action.payload.isShowPopUpSortFilter,
          isCheckAllOptionFilter: action.payload.isCheckAllOptionFilter,
          isFilter: action.payload.isFilter,
          isClear: action.payload.isClear,
          columnSortFiltering: action.payload.columnSortFiltering,
          lastColumnFilter: action.payload.lastColumnFilter,
          listFilterDisplay: action.payload.listFilterDisplay,
          listFilterDisplayOrigin: action.payload.listFilterDisplayOrigin,
          listCurrentFilter: action.payload.listCurrentFilter,
          listSorted: action.payload.listSorted,
          templateGroupsDisplay: action.payload.templateGroupsDisplay,
          headerColumns: action.payload.headerColumns,
          headerColumnsOriginal: action.payload.headerColumnsOriginal,
          mediaValidator: action.payload.mediaValidator,
          areaGroups: action.payload.areaGroups,
          isGroup: action.payload.isGroup,
          groupSelected: action.payload.groupSelected,
          isMultipleTimetables: action.payload.isMultipleTimetables
        }
      };
    case ComponentStateActionEnum.RESET_LCD_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialLCDEditorState
      };
    default:
      return state;
  }
}

export function lcdLayoutEditorReducer(state = lcdEditorStartupState, action: AppActions) {
  return handleLcdLayoutEditorReducer(state, action);
}

// Initial LED Editor Editor State
const initialLEDEditorState: ILEDEditorComponentState = {
  isChangeLayout: false,
  isShowedTemplateEditor: false,
  templateGroupSelected: undefined,
  templateGroups: [],
  templateSelected: undefined,
  templatesOfGroup: [],
  templates: [],
  isChangedData: false,
  templateGroupModeFilter: LEDTemplateModeEnum.ALL,
  templateGroupsOrigin: [],
  indexWordGroups: [],
  isHiddenTemplates: false,
  toolEditTemplateSelected: EditTemplateToolsEnum.SELECT_AREA,
  isShowAlignTool: false,
  scalePreview: new ScaleEntity('100', 1),
  isClearAllBorderCanvas: false,
  areasSelected: [],
  isHiddenSettingArea: false,
  isHiddenAreas: false,
  areas: [],
  areaSelected: undefined,
  fontsBitmap: [],
  fontColor: undefined,
  backgroundColor: undefined,
  outlineColor: undefined,
  fontNamesBmp: [],
  fontsDisplay: [],
  oldTemplateSelected: undefined,
  isShowAreasOnPreview: true
};
const ledEditorStartupState: any = {
  stateOfComponent: initialLEDEditorState
};

export function handleLedLayoutEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_LED_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          isShowedTemplateEditor: action.payload.isShowedTemplateEditor,
          templateGroupSelected: action.payload.templateGroupSelected,
          templateGroups: action.payload.templateGroups,
          templateSelected: action.payload.templateSelected,
          templatesOfGroup: action.payload.templatesOfGroup,
          templates: action.payload.templates,
          isChangedData: action.payload.isChangedData,
          templateGroupModeFilter: action.payload.templateGroupModeFilter,
          templateGroupsOrigin: action.payload.templateGroupsOrigin,
          indexWordGroups: action.payload.indexWordGroups,
          isHiddenTemplates: action.payload.isHiddenTemplates,
          toolEditTemplateSelected: action.payload.toolEditTemplateSelected,
          isShowAlignTool: action.payload.isShowAlignTool,
          scalePreview: action.payload.scalePreview,
          isClearAllBorderCanvas: action.payload.isClearAllBorderCanvas,
          areasSelected: action.payload.areasSelected,
          isHiddenSettingArea: action.payload.isHiddenSettingArea,
          isHiddenAreas: action.payload.isHiddenAreas,
          areas: action.payload.areas,
          areaSelected: action.payload.areaSelected,
          fontsBitmap: action.payload.fontsBitmap,
          fontColor: action.payload.fontColor,
          backgroundColor: action.payload.backgroundColor,
          outlineColor: action.payload.outlineColor,
          fontNamesBmp: action.payload.fontNamesBmp,
          fontsDisplay: action.payload.fontsDisplay,
          oldTemplateSelected: action.payload.oldTemplateSelected,
          isShowAreasOnPreview: action.payload.isShowAreasOnPreview
        }
      };
    case ComponentStateActionEnum.RESET_LED_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialLEDEditorState
      };
    default:
      return state;
  }
}

export function ledLayoutEditorReducer(state = ledEditorStartupState, action: AppActions) {
  return handleLedLayoutEditorReducer(state, action);
}

// Initial Destination Sign Editor State
const initialDestinationSignState: IDestinationSignEditorComponentState = {
  isCheckedAllBusStop: false,
  isCheckedAllRoute: false,
  isPlayPreview: false,
  isChangeBusStopChecked: false,
  isEditDataRoute: false,
  isEditDataBusStop: false,
  isChangeData: false,
  isEnlargePreview: true,
  isDrawnBusStop: false,
  isChangeLayout: false,
  indexBusStopPreview: -1,
  listFontFolderBmp: [],
  routeSelected: undefined,
  edsRoutes: [],
  busStopSelected: undefined,
  adjustedRouteNoEdit: undefined,
  adjustedRouteNameEdit: undefined,
  adjustedBusStopNameEdit: undefined,
  oldBusStops: [],
  isStopPreviewBusStop: false
};
const destinationSignStartupState: any = {
  stateOfComponent: initialDestinationSignState
};

export function handleDestinationSignEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_DESTINATION_SIGN_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isCheckedAllBusStop: action.payload.isCheckedAllBusStop,
          isCheckedAllRoute: action.payload.isCheckedAllRoute,
          isPlayPreview: action.payload.isPlayPreview,
          isChangeBusStopChecked: action.payload.isChangeBusStopChecked,
          isEditDataRoute: action.payload.isEditDataRoute,
          isEditDataBusStop: action.payload.isEditDataBusStop,
          isChangeData: action.payload.isChangeData,
          isEnlargePreview: action.payload.isEnlargePreview,
          isDrawnBusStop: action.payload.isDrawnBusStop,
          isChangeLayout: action.payload.isChangeLayout,
          indexBusStopPreview: action.payload.indexBusStopPreview,
          listFontFolderBmp: action.payload.listFontFolderBmp,
          routeSelected: action.payload.routeSelected,
          edsRoutes: action.payload.edsRoutes,
          busStopSelected: action.payload.busStopSelected,
          adjustedRouteNoEdit: action.payload.adjustedRouteNoEdit,
          adjustedRouteNameEdit: action.payload.adjustedRouteNameEdit,
          adjustedBusStopNameEdit: action.payload.adjustedBusStopNameEdit,
          oldBusStops: action.payload.oldBusStops,
          isStopPreviewBusStop: action.payload.isStopPreviewBusStop
        }
      };
    case ComponentStateActionEnum.RESET_DESTINATION_SIGN_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialDestinationSignState
      };
    default:
      return state;
  }
}

export function destinationSignEditorReducer(state = destinationSignStartupState, action: AppActions) {
  return handleDestinationSignEditorReducer(state, action);
}

// Initial Bus Info Display Editor State
const initialBusInfoDisplayState: IBusInfoDisplayEditorComponentState = {
  isChangeLayout: false,
  routes: [],
  routeSelected: undefined,
  isCheckedAll: false,
  areasDisplay1: undefined,
  areasDisplay2: undefined,
  eventSelected: undefined,
  index: 0,
  busStopSelected: undefined,
  isSetting: true,
  isEnlargeScreen: false,
  isTextArea: false,
  isChangedData: false,
  isPlay: false,
  isShowVertical: true
};
const busInfoDisplayStartupState: any = {
  stateOfComponent: initialBusInfoDisplayState
};

export function handleBusInfoDisplayEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_BUS_INFO_DISPLAY_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          routes: action.payload.routes,
          routeSelected: action.payload.routeSelected,
          isCheckedAll: action.payload.isCheckedAll,
          areasDisplay1: action.payload.areasDisplay1,
          areasDisplay2: action.payload.areasDisplay2,
          eventSelected: action.payload.eventSelected,
          index: action.payload.index,
          busStopSelected: action.payload.busStopSelected,
          isSetting: action.payload.isSetting,
          isEnlargeScreen: action.payload.isEnlargeScreen,
          isTextArea: action.payload.isTextArea,
          isChangedData: action.payload.isChangedData,
          isPlay: action.payload.isPlay,
          isShowVertical: action.payload.isShowVertical
        }
      };
    case ComponentStateActionEnum.RESET_BUS_INFO_DISPLAY_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialBusInfoDisplayState
      };
    default:
      return state;
  }
}

export function busInfoDisplayEditorReducer(state = busInfoDisplayStartupState, action: AppActions) {
  return handleBusInfoDisplayEditorReducer(state, action);
}

// Initial Digital Signage Editor State
const initialDigitalSignageState: IDigitalSignageEditorComponentState = {
  isChangeLayout: false,
  isShowChannel: false,
  channels: [],
  dailySchedules: [],
  selectedChannel: undefined,
  dailyScheduleSelected: undefined,
  selectedDay: undefined,
  channelTimestamps: [],
  timelineDivision: 0,
  selectedProgram: undefined,
  isChangeDuration: false,
  isChangeStart: false,
  currentProgress: 0,
  currentProgramIndex: -1,
  isOnVideo: false,
  previewVideo: undefined,
  selectedMonth: undefined,
  selectedYear: undefined,
  index: 0,
  calendarDay: undefined,
  isNextMonth: false,
  isPreviousMonth: true,
  chosenProgram: undefined,
  channelData: undefined,
  timeStart: 0,
  isChangeProgram: false,
  isChangeDailySchedule: false,
  isChangeChannel: false,
  contentDaysMonth: undefined,
  isChangedData: false,
  mediaListOfSequence: []
};
const digitalSignageStartupState: any = {
  stateOfComponent: initialDigitalSignageState
};

export function handleDigitalSignageEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_DIGITAL_SIGNAGE_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          isShowChannel: action.payload.isShowChannel,
          channels: action.payload.channels,
          dailySchedules: action.payload.dailySchedules,
          selectedChannel: action.payload.selectedChannel,
          dailyScheduleSelected: action.payload.dailyScheduleSelected,
          selectedDay: action.payload.selectedDay,
          channelTimestamps: action.payload.channelTimestamps,
          timelineDivision: action.payload.timelineDivision,
          selectedProgram: action.payload.selectedProgram,
          isChangeDuration: action.payload.isChangeDuration,
          isChangeStart: action.payload.isChangeStart,
          currentProgress: action.payload.currentProgress,
          currentProgramIndex: action.payload.currentProgramIndex,
          isOnVideo: action.payload.isOnVideo,
          previewVideo: action.payload.previewVideo,
          selectedMonth: action.payload.selectedMonth,
          selectedYear: action.payload.selectedYear,
          index: action.payload.index,
          calendarDay: action.payload.calendarDay,
          isNextMonth: action.payload.isNextMonth,
          isPreviousMonth: action.payload.isPreviousMonth,
          chosenProgram: action.payload.chosenProgram,
          channelData: action.payload.channelData,
          timeStart: action.payload.timeStart,
          isChangeProgram: action.payload.isChangeProgram,
          isChangeDailySchedule: action.payload.isChangeDailySchedule,
          isChangeChannel: action.payload.isChangeChannel,
          contentDaysMonth: action.payload.contentDaysMonth,
          isChangedData: action.payload.isChangedData,
          mediaListOfSequence: action.payload.mediaListOfSequence
        }
      };
    case ComponentStateActionEnum.RESET_DIGITAL_SIGNAGE_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialDigitalSignageState
      };
    default:
      return state;
  }
}

export function digitalSignageEditorReducer(state = digitalSignageStartupState, action: AppActions) {
  return handleDigitalSignageEditorReducer(state, action);
}

// Initial Delivery Manager Editor State
const initialDeliveryManagerState: IDeliveryManagerComponentState = {
  isChangeLayout: false,
  deviceList: undefined,
  deliverySelected: undefined,
  columns: [],
  listCustomTag: [],
  devicesDisplay: undefined,
  lastFiltered: Constant.EMPTY,
  isSortAndFilter: false,
  listCurrentFilter: {},
  listSorted: [],
  isClear: false,
  listFilterDisplayOrigin: undefined,
  listFilterDisplay: undefined,
  hasComment: true,
  isCheckedAll: false,
  isSort: '',
  isFilter: false,
  deliveryManagerSetting: undefined,
  devicesInProgress: undefined,
  isShowDivEmergency: false,
  emergencyData: undefined,
  templateMains: undefined,
  templateSelected: undefined,
  isPlay: false
};
const deliveryManagerStartupState: any = {
  stateOfComponent: initialDeliveryManagerState
};

export function handleDeliveryManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_DELIVERY_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          deviceList: action.payload.deviceList,
          deliverySelected: action.payload.deliverySelected,
          columns: action.payload.columns,
          listCustomTag: action.payload.listCustomTag,
          devicesDisplay: action.payload.devicesDisplay,
          lastFiltered: action.payload.lastFiltered,
          isSortAndFilter: action.payload.isSortAndFilter,
          listCurrentFilter: action.payload.listCurrentFilter,
          listSorted: action.payload.listSorted,
          isClear: action.payload.isClear,
          listFilterDisplayOrigin: action.payload.listFilterDisplayOrigin,
          listFilterDisplay: action.payload.listFilterDisplay,
          hasComment: action.payload.hasComment,
          isCheckedAll: action.payload.isCheckedAll,
          isSort: action.payload.isSort,
          isFilter: action.payload.isFilter,
          deliveryManagerSetting: action.payload.deliveryManagerSetting,
          devicesInProgress: action.payload.devicesInProgress,
          isShowDivEmergency: action.payload.isShowDivEmergency,
          emergencyData: action.payload.emergencyData,
          templateMains: action.payload.templateMains,
          templateSelected: action.payload.templateSelected,
          isPlay: action.payload.isPlay
        }
      };
    case ComponentStateActionEnum.RESET_DELIVERY_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialDeliveryManagerState
      };
    default:
      return state;
  }
}

export function deliveryManagerReducer(state = deliveryManagerStartupState, action: AppActions) {
  return handleDeliveryManagerReducer(state, action);
}

// Initial Dashboard State
const initialDashboardState: IDashboardComponentState = {
  isChangeLayout: false,
  isActiveDeviceMonitorTab: false,
  isActiveContentReportTab: false,
  isActivePropertiesTab: false,
  isActiveLogTab: false,
  isHideCol2: false,
  isChangedGrandTotal: false,
  isChangedTotal: false,
  period: 0,
  mediaLogContentSelected: undefined,
  mediaTabProperties: undefined,
  conditionFilterMediaLog: undefined,
  conditionFilterDeviceLog: undefined,
  mediaNameFilters: [],
  mediaLogContents: [],
  dateAndNumberPlays: [],
  mediaNamesToDisplay: [],
  customTags: [],
  deviceLogSelected: undefined,
  deviceLogs: [],
  allDevices: [],
  customTagSubject: undefined,
  isOldPeriodMonth: undefined,
  timeFrom: undefined,
  timeTo: undefined,
  timeFromDisplay: undefined,
  timeToDisplay: undefined,
  firstDateOfWeek: undefined,
  endDateOfWeek: undefined,
  settingDeviceMonitorAutoRefresh: undefined,
  intervalCurrentStatusReport: undefined,
  config: undefined,
  subTotalPlayPerDay: undefined,
  mediaLogPerDevicesDisplay: [],
  columns: [],
  isSortFilter: false,
  isCheckAllOptionFilter: true,
  columnSortFiltering: undefined,
  isShowPopUpSortFilter: false,
  lastColumnFilter: undefined,
  listFilterDisplay: [],
  listFilterDisplayOrigin: [],
  mediaLogPerDevicesFilterDisplay: undefined,
  isFilter: false,
  isClear: false,
  listCurrentFilter: undefined,
  listSorted: [],
  mediaObjectInDB: [],
  mediaTypesToDisplay: [],
  isChangedTimeFromContentReport: false,
  isChangedTimeToContentReport: false,
  hasDataInDeviceMonitorTab: false,
  dateHeaderPeriod: 0
};
const dashboardStartupState: any = {
  stateOfComponent: initialDashboardState
};

export function handleDashboardReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_DASHBOARD_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          isActiveDeviceMonitorTab: action.payload.isActiveDeviceMonitorTab,
          isActiveContentReportTab: action.payload.isActiveContentReportTab,
          isActivePropertiesTab: action.payload.isActivePropertiesTab,
          isActiveLogTab: action.payload.isActiveLogTab,
          isHideCol2: action.payload.isHideCol2,
          isChangedGrandTotal: action.payload.isChangedGrandTotal,
          isChangedTotal: action.payload.isChangedTotal,
          period: action.payload.period,
          mediaLogContentSelected: action.payload.mediaLogContentSelected,
          mediaTabProperties: action.payload.mediaTabProperties,
          conditionFilterMediaLog: action.payload.conditionFilterMediaLog,
          conditionFilterDeviceLog: action.payload.conditionFilterDeviceLog,
          mediaNameFilters: action.payload.mediaNameFilters,
          mediaLogContents: action.payload.mediaLogContents,
          dateAndNumberPlays: action.payload.dateAndNumberPlays,
          mediaNamesToDisplay: action.payload.mediaNamesToDisplay,
          customTags: action.payload.customTags,
          deviceLogSelected: action.payload.deviceLogSelected,
          deviceLogs: action.payload.deviceLogs,
          allDevices: action.payload.allDevices,
          customTagSubject: action.payload.customTagSubject,
          isOldPeriodMonth: action.payload.isOldPeriodMonth,
          timeFrom: action.payload.timeFrom,
          timeTo: action.payload.timeTo,
          timeFromDisplay: action.payload.timeFromDisplay,
          timeToDisplay: action.payload.timeToDisplay,
          firstDateOfWeek: action.payload.firstDateOfWeek,
          endDateOfWeek: action.payload.endDateOfWeek,
          settingDeviceMonitorAutoRefresh: action.payload.settingDeviceMonitorAutoRefresh,
          intervalCurrentStatusReport: action.payload.intervalCurrentStatusReport,
          config: action.payload.config,
          subTotalPlayPerDay: action.payload.subTotalPlayPerDay,
          mediaLogPerDevicesDisplay: action.payload.mediaLogPerDevicesDisplay,
          columns: action.payload.columns,
          isSortFilter: action.payload.isSortFilter,
          isCheckAllOptionFilter: action.payload.isCheckAllOptionFilter,
          columnSortFiltering: action.payload.columnSortFiltering,
          isShowPopUpSortFilter: action.payload.isShowPopUpSortFilter,
          lastColumnFilter: action.payload.lastColumnFilter,
          listFilterDisplay: action.payload.listFilterDisplay,
          listFilterDisplayOrigin: action.payload.listFilterDisplayOrigin,
          mediaLogPerDevicesFilterDisplay: action.payload.mediaLogPerDevicesFilterDisplay,
          isFilter: action.payload.isFilter,
          isClear: action.payload.isClear,
          listCurrentFilter: action.payload.listCurrentFilter,
          listSorted: action.payload.listSorted,
          mediaObjectInDB: action.payload.mediaObjectInDB,
          mediaTypesToDisplay: action.payload.mediaTypesToDisplay,
          isChangedTimeFromContentReport: action.payload.isChangedTimeFromContentReport,
          isChangedTimeToContentReport: action.payload.isChangedTimeToContentReport,
          hasDataInDeviceMonitorTab: action.payload.hasDataInDeviceMonitorTab,
          dateHeaderPeriod: action.payload.dateHeaderPeriod
        }
      };
    case ComponentStateActionEnum.RESET_DASHBOARD_STATE:
      return {
        ...state,
        stateOfComponent: initialDashboardState
      };
    default:
      return state;
  }
}

export function dashboardReducer(state = dashboardStartupState, action: AppActions) {
  return handleDashboardReducer(state, action);
}

// Initial Station Display State
const initialStationState: IStationDisplayEditorComponentState = {
  isChangeLayout: false,
  isEnlargeScreen: false,
  busStops: [],
  routes: [],
  isCheckedAll: false,
  cellSelected: undefined,
  routeBusStops: [],
  isDisplay2: false,
  busStopSelected: undefined,
  isPlay: false,
  listAllCells: [],
  indexPreview: 0,
  isControlPreview: false,
  dataCellsPreview: [],
  isChangedData: false,
  stationContentMediaFiles: [],
  deletedCells: [],
  tableBodyElement: undefined,
  isShowVertical: true
};
const stationStartupState: any = {
  stateOfComponent: initialStationState
};

export function handleStationDisplayEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_STATION_DISPLAY_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          isEnlargeScreen: action.payload.isEnlargeScreen,
          busStops: action.payload.busStops,
          routes: action.payload.routes,
          isCheckedAll: action.payload.isCheckedAll,
          cellSelected: action.payload.cellSelected,
          routeBusStops: action.payload.routeBusStops,
          isDisplay2: action.payload.isDisplay2,
          busStopSelected: action.payload.busStopSelected,
          isPlay: action.payload.isPlay,
          listAllCells: action.payload.listAllCells,
          indexPreview: action.payload.indexPreview,
          isControlPreview: action.payload.isControlPreview,
          dataCellsPreview: action.payload.dataCellsPreview,
          isChangedData: action.payload.isChangedData,
          stationContentMediaFiles: action.payload.stationContentMediaFiles,
          deletedCells: action.payload.deletedCells,
          tableBodyElement: action.payload.tableBodyElement,
          isShowVertical: action.payload.isShowVertical
        }
      };
    case ComponentStateActionEnum.RESET_STATION_DISPLAY_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialStationState
      };
    default:
      return state;
  }
}

export function stationDisplayEditorReducer(state = stationStartupState, action: AppActions) {
  return handleStationDisplayEditorReducer(state, action);
}

// Initial Signage Display State
const initialSignageState: ISignageDisplayEditorComponentState = {
  isChangeLayout: false,
  isEnlargeScreen: false,
  isCheckedAll: false,
  isChangedData: false,
  isDisplay2: false,
  styleSelected: undefined,
  styleDetailSelected: undefined,
  styles: [],
  styleDetails: [],
  areasDisplay1: [],
  areasDisplay2: [],
  styleNameEdit: undefined,
  styleNoEdit: undefined,
  suffixEdit: undefined,
  isShowTextColumn: false,
  isShowDetail: false,
  isPlay: false,
  buttonsPreviewDisplay1: [],
  buttonsPreviewDisplay2: [],
  isShowVertical: true,
  mediaSetting: undefined
};
const signageStartupState: any = {
  stateOfComponent: initialSignageState
};

export function handleSignageDisplayEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_SIGNAGE_DISPLAY_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          isEnlargeScreen: action.payload.isEnlargeScreen,
          isCheckedAll: action.payload.isCheckedAll,
          isChangedData: action.payload.isChangedData,
          isDisplay2: action.payload.isDisplay2,
          styleSelected: action.payload.styleSelected,
          styleDetailSelected: action.payload.styleDetailSelected,
          styles: action.payload.styles,
          styleDetails: action.payload.styleDetails,
          areasDisplay1: action.payload.areasDisplay1,
          areasDisplay2: action.payload.areasDisplay2,
          styleNameEdit: action.payload.styleNameEdit,
          styleNoEdit: action.payload.styleNoEdit,
          suffixEdit: action.payload.suffixEdit,
          isShowTextColumn: action.payload.isShowTextColumn,
          isShowDetail: action.payload.isShowDetail,
          isPlay: action.payload.isPlay,
          buttonsPreviewDisplay1: action.payload.buttonsPreviewDisplay1,
          buttonsPreviewDisplay2: action.payload.buttonsPreviewDisplay2,
          isShowVertical: action.payload.isShowVertical,
          mediaSetting: action.payload.mediaSetting
        }
      };
    case ComponentStateActionEnum.RESET_SIGNAGE_DISPLAY_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialSignageState
      };
    default:
      return state;
  }
}

export function signageDisplayEditorReducer(state = signageStartupState, action: AppActions) {
  return handleSignageDisplayEditorReducer(state, action);
}

// Initial External Content Manager State
const initialExternalContentManagerState: IExternalContentManagerComponentState = {
  isChangeLayout: false,
  indexWordGroups: undefined,
  contents: undefined,
  isActiveNewsTab: false,
  isEnlargePreview: false,
  isChangeMunicipalCode: false,
  contentSelected: undefined,
  contentDetailSelected: undefined,
  areas: undefined,
  isPlayPreview: false,
  newsCurrentPage: 1,
  areaDisabledOptions: undefined,
  inputFocusCurrent: undefined,
  contentCloneSelected: undefined,
  isChangedData: false,
  isChangedNewsTab: false,
  isChangedWeatherTab: false
};
const externalContentManagerStartupState: any = {
  stateOfComponent: initialExternalContentManagerState
};

export function handleExternalContentManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_EXTERNAL_CONTENT_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          indexWordGroups: action.payload.indexWordGroups,
          contents: action.payload.contents,
          isActiveNewsTab: action.payload.isActiveNewsTab,
          isEnlargePreview: action.payload.isEnlargePreview,
          isChangeMunicipalCode: action.payload.isChangeMunicipalCode,
          contentSelected: action.payload.contentSelected,
          contentDetailSelected: action.payload.contentDetailSelected,
          areas: action.payload.areas,
          isPlayPreview: action.payload.isPlayPreview,
          newsCurrentPage: action.payload.newsCurrentPage,
          areaDisabledOptions: action.payload.areaDisabledOptions,
          inputFocusCurrent: action.payload.inputFocusCurrent,
          contentCloneSelected: action.payload.contentCloneSelected,
          isChangedData: action.payload.isChangedData,
          isChangedNewsTab: action.payload.isChangedNewsTab,
          isChangedWeatherTab: action.payload.isChangedWeatherTab
        }
      };
    case ComponentStateActionEnum.RESET_EXTERNAL_CONTENT_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialExternalContentManagerState
      };
    default:
      return state;
  }
}

export function externalContentManagerReducer(state = externalContentManagerStartupState, action: AppActions) {
  return handleExternalContentManagerReducer(state, action);
}

const initialUserInfoState: IUserInfoState = {
  user: undefined,
  privileges: undefined
};
const userInfoStartupState: any = {
  stateOfComponent: initialUserInfoState
};

export function handleUserInfoReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_USER_INFO_STATE:
      return {
        ...state,
        stateOfComponent: {
          user: action.payload.user,
          privileges: action.payload.privileges
        }
      };
    case ComponentStateActionEnum.RESET_USER_INFO_STATE:
      return {
        ...state,
        stateOfComponent: initialUserInfoState
      };
    default:
      return state;
  }
}

export function userInfoReducer(state = userInfoStartupState, action: AppActions) {
  return handleUserInfoReducer(state, action);
}

// Initial Timetable Editor State
const initialTimetableState: ITimetableEditorComponentState = {
  isChangeLayout: false,
  unUsedColors: [],
  nameEdit: undefined,
  noEdit: undefined,
  suffixEdit: undefined,
  isDisplay2: false,
  timetableSelected: undefined,
  timetables: [],
  externalContentOfTimetable: undefined,
  isSchedule: true,
  contentDaysMonth: [],
  groupDevices: [],
  selectedMonth: undefined,
  selectedYear: undefined,
  isNextMonth: false,
  isPreviousMonth: true,
  selectedDeviceCalendar: undefined,
  listMonth: [],
  currentIndexTimetableSchedule: undefined,
  isChangedData: false,
  externalContentOfStyle: [],
  mediaSetting: undefined,
  isPlay: false,
  buttonsPreviewDisplay1: [],
  buttonsPreviewDisplay2: [],
  isZoom: false,
  isPan: false,
  isEnlargePreview: false,
  headersDisplay: [],
  colorBeingUsed: [],
  freeAreaTimetableMediaFiles: new Array(),
  filesData: [],
  isOnlyFreeTextArea: false,
  isOnlyFreePictureArea: false,
  isDisabledButtonAdd: false,
  isShowFreeArea: false,
  timetableDetailsClone: new Array(),
  timetablesDisplay: [],
  lastColumnFilter: undefined,
  listFilterDisplay: [],
  listFilterDisplayOrigin: [],
  timetablesFilterDisplay: [],
  isDisabledButtonSort: false,
  referencePositionColumnsByTemplate: [],
  areasDisplay1: [],
  areasDisplay2: [],
  isUnlimited: false,
  isUnlimitedDataFromDialogDataRecurrence: false,
  areaSwitchingTiming: 0
};
const timetableStartupState: any = {
  stateOfComponent: initialTimetableState
};

export function handleTimetableEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_TIMETABLE_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          unUsedColors: action.payload.unUsedColors,
          nameEdit: action.payload.nameEdit,
          noEdit: action.payload.noEdit,
          suffixEdit: action.payload.suffixEdit,
          isDisplay2: action.payload.isDisplay2,
          timetableSelected: action.payload.timetableSelected,
          timetables: action.payload.timetables,
          externalContentOfTimetable: action.payload.externalContentOfTimetable,
          isSchedule: action.payload.isSchedule,
          contentDaysMonth: action.payload.contentDaysMonth,
          groupDevices: action.payload.groupDevices,
          selectedMonth: action.payload.selectedMonth,
          selectedYear: action.payload.selectedYear,
          isNextMonth: action.payload.isNextMonth,
          isPreviousMonth: action.payload.isPreviousMonth,
          selectedDeviceCalendar: action.payload.selectedDeviceCalendar,
          listMonth: action.payload.listMonth,
          currentIndexTimetableSchedule: action.payload.currentIndexTimetableSchedule,
          isChangedData: action.payload.isChangedData,
          mediaSetting: action.payload.mediaSetting,
          isPlay: action.payload.isPlay,
          externalContentOfStyle: action.payload.externalContentOfStyle,
          buttonsPreviewDisplay1: action.payload.buttonsPreviewDisplay1,
          buttonsPreviewDisplay2: action.payload.buttonsPreviewDisplay2,
          isZoom: action.payload.isZoom,
          isPan: action.payload.isPan,
          isEnlargePreview: action.payload.isEnlargePreview,
          headersDisplay: action.payload.headersDisplay,
          colorBeingUsed: action.payload.colorBeingUsed,
          freeAreaTimetableMediaFiles: action.payload.freeAreaTimetableMediaFiles,
          filesData: action.payload.filesData,
          isOnlyFreeTextArea: action.payload.isOnlyFreeTextArea,
          isOnlyFreePictureArea: action.payload.isOnlyFreePictureArea,
          isDisabledButtonAdd: action.payload.isDisabledButtonAdd,
          isShowTextColumn: action.payload.isShowTextColumn,
          isShowFreeArea: action.payload.isShowFreeArea,
          timetableDetailsClone: action.payload.timetableDetailsClone,
          timetablesDisplay: action.payload.timetablesDisplay,
          lastColumnFilter: action.payload.lastColumnFilter,
          listFilterDisplay: action.payload.listFilterDisplay,
          listFilterDisplayOrigin: action.payload.listFilterDisplayOrigin,
          timetablesFilterDisplay: action.payload.timetablesFilterDisplay,
          isDisabledButtonSort: action.payload.isDisabledButtonSort,
          referencePositionColumnsByTemplate: action.payload.referencePositionColumnsByTemplate,
          areasDisplay1: action.payload.areasDisplay1,
          areasDisplay2: action.payload.areasDisplay2,
          isUnlimited: action.payload.isUnlimited,
          isUnlimitedDataFromDialogDataRecurrence: action.payload.isUnlimitedDataFromDialogDataRecurrence,
          areaSwitchingTiming: action.payload.areaSwitchingTiming
        }
      };
    case ComponentStateActionEnum.RESET_TIMETABLE_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialTimetableState
      };
    default:
      return state;
  }
}

export function timetableDisplayEditorReducer(state = timetableStartupState, action: AppActions) {
  return handleTimetableEditorReducer(state, action);
}

// Initial Timetable Operation Manager State
const initialTimetableOperationManagerState: ITimetableOperationManagerComponentState = {
  isChangeLayout: false,
  isEnlargePreview: false,
  isEditDynamicMessageMode: false,
  isEditEmergencyMode: false,
  isViewMonitorMode: false,
  isZoom: false,
  isPan: false,
  deviceSelected: undefined,
  devices: undefined,
  schedules: undefined,
  scheduleColumns: undefined,
  activeScheduleRow: undefined,
  timetableSelected: undefined,
  dynamicMessages: undefined,
  emergencyData: new EmergencyData('', -1),
  buttonsPreviewDisplay1: undefined,
  buttonsPreviewDisplay2: undefined,
  templateSelectedTypeDisplay1: DestinationEnum.MAIN,
  templateSelectedTypeDisplay2: DestinationEnum.MAIN,
  linkAreasDisplay1: undefined,
  linkAreasDisplay2: undefined,
  currentIndexTimetableSchedule: 0,
  scheduleOperationInfo: undefined,
  scheduleRowIndex: undefined,
  timetablePositionRowDisplay1Set: undefined,
  timetablePositionRowDisplay2Set: undefined,
  isPlayPreview: false,
  operationInfoOptions: [],
  changeoverOffset: 0,
  isRedrawMonitor: false,
  oldDynamicMessages: undefined,
  oldOperationInfos: undefined,
  oldScheduleRowIndex: undefined,
  scheduleDisplayIndex: undefined,
  timeDateLine: 0,
  timetableModePreview: undefined,
  typeDisplay1ModePreview: undefined,
  typeDisplay2ModePreview: undefined,
  monitorResponseData: undefined,
  isNetworkOK: false,
  emergencyMediaIdOld: undefined,
  areaSwitchingTiming: 0,
  filesData: [],
  isOnlyTextArea: false,
  isOnlyPictureArea: false,
  mediaFilesDroppedOperation: [],
  mediaIdsToDelete: []
};
const timetableOperationManagerStartupState: any = {
  stateOfComponent: initialTimetableOperationManagerState
};

export function handleTimetableOperationManagerReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_TIMETABLE_OPERATION_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          isEnlargePreview: action.payload.isEnlargePreview,
          isEditDynamicMessageMode: action.payload.isEditDynamicMessageMode,
          isEditEmergencyMode: action.payload.isEditEmergencyMode,
          isViewMonitorMode: action.payload.isViewMonitorMode,
          isZoom: action.payload.isZoom,
          isPan: action.payload.isPan,
          deviceSelected: action.payload.deviceSelected,
          devices: action.payload.devices,
          schedules: action.payload.schedules,
          scheduleColumns: action.payload.scheduleColumns,
          activeScheduleRow: action.payload.activeScheduleRow,
          timetableSelected: action.payload.timetableSelected,
          dynamicMessages: action.payload.dynamicMessages,
          emergencyData: action.payload.emergencyData,
          buttonsPreviewDisplay1: action.payload.buttonsPreviewDisplay1,
          buttonsPreviewDisplay2: action.payload.buttonsPreviewDisplay2,
          templateSelectedTypeDisplay1: action.payload.templateSelectedTypeDisplay1,
          templateSelectedTypeDisplay2: action.payload.templateSelectedTypeDisplay2,
          linkAreasDisplay1: action.payload.linkAreasDisplay1,
          linkAreasDisplay2: action.payload.linkAreasDisplay2,
          currentIndexTimetableSchedule: action.payload.currentIndexTimetableSchedule,
          scheduleOperationInfo: action.payload.scheduleOperationInfo,
          scheduleRowIndex: action.payload.scheduleRowIndex,
          timetablePositionRowDisplay1Set: action.payload.timetablePositionRowDisplay1Set,
          timetablePositionRowDisplay2Set: action.payload.timetablePositionRowDisplay2Set,
          isPlayPreview: action.payload.isPlayPreview,
          operationInfoOptions: action.payload.operationInfoOptions,
          changeoverOffset: action.payload.changeoverOffset,
          isRedrawMonitor: action.payload.isRedrawMonitor,
          oldDynamicMessages: action.payload.oldDynamicMessages,
          oldOperationInfos: action.payload.oldOperationInfos,
          oldScheduleRowIndex: action.payload.oldScheduleRowIndex,
          scheduleDisplayIndex: action.payload.scheduleDisplayIndex,
          timeDateLine: action.payload.timeDateLine,
          timetableModePreview: action.payload.timetableModePreview,
          typeDisplay1ModePreview: action.payload.typeDisplay1ModePreview,
          typeDisplay2ModePreview: action.payload.typeDisplay2ModePreview,
          monitorResponseData: action.payload.monitorResponseData,
          isNetworkOK: action.payload.isNetworkOK,
          emergencyMediaIdOld: action.payload.emergencyMediaIdOld,
          areaSwitchingTiming: action.payload.areaSwitchingTiming,
          filesData: action.payload.filesData,
          isOnlyTextArea: action.payload.isOnlyTextArea,
          isOnlyPictureArea: action.payload.isOnlyPictureArea,
          mediaFilesDroppedOperation: action.payload.mediaFilesDroppedOperation,
          mediaIdsToDelete: action.payload.mediaIdsToDelete
        }
      };
    case ComponentStateActionEnum.RESET_TIMETABLE_OPERATION_MANAGER_STATE:
      return {
        ...state,
        stateOfComponent: initialTimetableOperationManagerState
      };
    default:
      return state;
  }
}

export function timetableOperationManagerReducer(state = timetableOperationManagerStartupState, action: AppActions) {
  return handleTimetableOperationManagerReducer(state, action);
}

// Initial Simple Signage Editor State
const initialSimpleSignageEditorState: ISimpleSignageEditorComponentState = {
  isChangeLayout: false,
  isEnlargePreview: true,
  isTabPlaylist: true,
  isPreviewOn: false,
  isShowPlaceholder: false,
  isOpenDatePicker: false,
  playlists: [],
  mediaOfSequences: [],
  oldMediaOfSequences: [],
  folders: [],
  medias: [],
  playlistSelected: undefined,
  isTypeList: true,
  folderSelected: undefined,
  mediaSelected: undefined,
  mediaOfSequenceSelected: undefined,
  totalTime: undefined,
  config: undefined,
  pointPercents: [],
  pointNumbers: [],
  isCheckedAll: false,
  contentDaysMonth: [],
  deviceCalendars: [],
  selectedMonth: undefined,
  selectedYear: undefined,
  isNextMonth: false,
  isPreviousMonth: true,
  selectedDeviceCalendar: undefined,
  groupDevices: [],
  selectedDay: undefined,
  mediasOfFolder: undefined,
  isChangeMediaOfSequence: false,
  oldMediasOfFolder: [],
  searchInputValue: undefined,
  isActiveButtonBack: false,
  oldValuePlaylistName: undefined,
  oldValueFolderName: undefined,
  devicesCalendarDisplayOrigin: [],
  timeCursorPointSecond: 0,
  colorBeingUsed: undefined,
  unUsedColors: [],
  isSelectedMediaOfSequence: false,
  simpleMediaValidator: undefined,
  totalTimeVideo: undefined,
  isUnlimited: false,
  isUnlimitedDataFromDialogDataRecurrence: false,
  isWaitingActionAPI: false
};
const simpleSignageEditorStartupState: any = {
  stateOfComponent: initialSimpleSignageEditorState
};

export function handleSimpleSignageEditorReducer(state: any, action: AppActions) {
  switch (action.type) {
    case ComponentStateActionEnum.SAVE_SIMPLE_SIGNAGE_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: {
          isChangeLayout: action.payload.isChangeLayout,
          isEnlargePreview: action.payload.isEnlargePreview,
          isTabPlaylist: action.payload.isTabPlaylist,
          isPreviewOn: action.payload.isPreviewOn,
          isShowPlaceholder: action.payload.isShowPlaceholder,
          isOpenDatePicker: action.payload.isOpenDatePicker,
          playlists: action.payload.playlists,
          mediaOfSequences: action.payload.mediaOfSequences,
          oldMediaOfSequences: action.payload.oldMediaOfSequences,
          folders: action.payload.folders,
          medias: action.payload.medias,
          playlistSelected: action.payload.playlistSelected,
          isTypeList: action.payload.isTypeList,
          folderSelected: action.payload.folderSelected,
          mediaSelected: action.payload.mediaSelected,
          mediaOfSequenceSelected: action.payload.mediaOfSequenceSelected,
          totalTime: action.payload.totalTime,
          config: action.payload.config,
          pointPercents: action.payload.pointPercents,
          pointNumbers: action.payload.pointNumbers,
          isCheckedAll: action.payload.isCheckedAll,
          contentDaysMonth: action.payload.contentDaysMonth,
          deviceCalendars: action.payload.deviceCalendars,
          selectedMonth: action.payload.selectedMonth,
          selectedYear: action.payload.selectedYear,
          isNextMonth: action.payload.isNextMonth,
          isPreviousMonth: action.payload.isPreviousMonth,
          selectedDeviceCalendar: action.payload.selectedDeviceCalendar,
          groupDevices: action.payload.groupDevices,
          selectedDay: action.payload.selectedDay,
          mediasOfFolder: action.payload.mediasOfFolder,
          isChangeMediaOfSequence: action.payload.isChangeMediaOfSequence,
          oldMediasOfFolder: action.payload.oldMediasOfFolder,
          searchInputValue: action.payload.searchInputValue,
          isActiveButtonBack: action.payload.isActiveButtonBack,
          oldValuePlaylistName: action.payload.oldValuePlaylistName,
          oldValueFolderName: action.payload.oldValueFolderName,
          devicesCalendarDisplayOrigin: action.payload.devicesCalendarDisplayOrigin,
          timeCursorPointSecond: action.payload.timeCursorPointSecond,
          colorBeingUsed: action.payload.colorBeingUsed,
          unUsedColors: action.payload.unUsedColors,
          isSelectedMediaOfSequence: action.payload.isSelectedMediaOfSequence,
          simpleMediaValidator: action.payload.simpleMediaValidator,
          totalTimeVideo: action.payload.totalTimeVideo,
          isUnlimited: action.payload.isUnlimited,
          isUnlimitedDataFromDialogDataRecurrence: action.payload.isUnlimitedDataFromDialogDataRecurrence,
          isWaitingActionAPI: action.payload.isWaitingActionAPI
        }
      };
    case ComponentStateActionEnum.RESET_SIMPLE_SIGNAGE_EDITOR_STATE:
      return {
        ...state,
        stateOfComponent: initialSimpleSignageEditorState
      };
    default:
      return state;
  }
}

export function simpleSignageEditorReducer(state = simpleSignageEditorStartupState, action: AppActions) {
  return handleSimpleSignageEditorReducer(state, action);
}
